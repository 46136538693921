<template>
  <div class="container is-fluid">
    <div class="columns">
      <div class="column is-12">
        <h4 class="page-header">
          <b-icon icon="star"></b-icon>
          <span>{{ $ml.get('bets_settled_head') }}</span>
        </h4>
      </div>
    </div>
    <div class="columns is-centered is-variable is-4">
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-primary">{{ $ml.get('dashboard_bets_count') }}</div>
          <div class="body">
            <div class="side-icon text-primary">
              <b-icon icon="star"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ new Intl.NumberFormat().format(counters.count) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-info">{{ $ml.get('dashboard_turnover') }}</div>
          <div class="body">
            <div class="side-icon has-text-info">
              <b-icon icon="reload"></b-icon>
            </div>
            <div class="side-info">
              <span><Money currency="EUR" :subunits=true v-bind:amount="parseFloat(counters.turnover)"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-success">{{ $ml.get('dashboard_profit') }}</div>
          <div class="body">
            <div class="side-icon has-text-success">
              <b-icon icon="currency-usd"></b-icon>
            </div>
            <div class="side-info">
              <span><Money :subunits=true currency="EUR" v-bind:amount="parseFloat(counters.profit)"/></span>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="dash-plate box">
          <div class="header is-warning">ROI</div>
          <div class="body">
            <div class="side-icon has-text-warning">
              <b-icon icon="finance"></b-icon>
            </div>
            <div class="side-info">
              <span>{{ counters.roi }} %</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit" novalidate>
      <b-loading :is-full-page="true" v-model="loading" :can-cancel="false"></b-loading>
      <div class="columns is-centered">
        <div class="column is-2">
          <b-field :label="$ml.get('bets_mode')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.modes"
                :options="bet_modes"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_status')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.statuses"
                :options="['WON', 'LOSE', 'REFUNDED']"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_sport')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.sports"
                :options="sports"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_bookmaker')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.bks"
                :options="bookmakers"/>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-2">
          <b-field :label="$ml.get('bets_market')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.markets"
                :options="markets"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_target')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.targets"
                :options="targets"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_strategy')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.strategies"
                :options="strategies"/>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_account')" class="plate">
            <multiselect
                :multiple="true"
                :close-on-select="false"
                :clear-on-select="false"
                v-model="form.rooms"
                :options="rooms"/>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-2">
          <b-field :label="$ml.get('bets_coef') + ' ' + $ml.get('common_from')" class="plate">
            <b-input size="is-small" v-model="form.coef_from"></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_coef') + ' ' + $ml.get('common_to')" class="plate">
            <b-input size="is-small" v-model="form.coef_to"></b-input>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_date') + ' ' + $ml.get('common_from')" class="plate">
            <b-datepicker
                size="is-small"
                v-model="form.date_from"
                :show-week-number="false"
                locale="ru-RU"
                icon="calendar-today"
                placeholder="Date"
                editable
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-2">
          <b-field :label="$ml.get('bets_date') + ' ' + $ml.get('common_to')" class="plate">
            <b-datepicker
                size="is-small"
                v-model="form.date_to"
                :show-week-number="false"
                locale="ru-RU"
                icon="calendar-today"
                placeholder="Date"
                editable
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column is-2">
          <b-button size="is-small" type="is-info" native-type="submit" outlined>
            {{ $ml.get('common_show') }}
          </b-button>
        </div>
      </div>
    </form>
    <div class="columns">
      <div class="column">
        <b-table
            :data="items"
            :paginated="true"
            :bordered="true"
            :striped="true"
            :per-page="perPage"
            :total="counters.count"
            :default-sort="[sortField, sortOrder]"
            @page-change="switchPage"
            @sort="onSort"
            backend-pagination
            backend-sorting>
          <b-table-column field="mode" width="80" :label="$ml.get('bets_mode')" sortable v-slot="props">
            {{ props.row.mode }}
          </b-table-column>
          <b-table-column field="bk" width="100" :label="$ml.get('bets_bookmaker')" sortable v-slot="props">
            <Bk :bk="props.row.bk"/>
          </b-table-column>
          <b-table-column field="room_id" width="200" :label="$ml.get('bets_account')" v-slot="props">
            {{ props.row.room_name }}
          </b-table-column>
          <b-table-column field="strategy_id" width="200" :label="$ml.get('bets_strategy')" v-slot="props">
            {{ props.row.strategy_name }}
          </b-table-column>
          <b-table-column field="status" width="80" :label="$ml.get('bets_status')" sortable v-slot="props">
            <State v-bind:state="props.row.status"/>
          </b-table-column>
          <b-table-column field="placed_coef" width="80" :label="$ml.get('bets_coef')" sortable v-slot="props">
            {{ props.row.p_coef }}
          </b-table-column>
          <b-table-column field="euro_stake" width="80" :label="$ml.get('bets_stake')" sortable v-slot="props">
            <div class="flexed">
              <Money v-bind:amount="props.row.stake" v-bind:currency="props.row.currency"/>
              /
              <Money v-bind:amount="props.row.euro_stake" currency="EUR"/>
            </div>
          </b-table-column>
          <b-table-column field="euro_result" width="80" :label="$ml.get('bets_result')" sortable v-slot="props">
            <div class="flexed">
              <Money v-bind:amount="props.row.result" v-bind:currency="props.row.currency"
                     v-if="props.row.result !== null"/>
              /
              <Money v-bind:amount="props.row.euro_result" currency="EUR" v-if="props.row.euro_result !== null"/>
            </div>
          </b-table-column>
          <b-table-column field="event" :label="$ml.get('bets_event_line')" v-slot="props">
            <div class="columns">
              <div class="column is-7 rightal">
                <span>{{ props.row.t1 }} - {{ props.row.t2 }}</span> <span
                  v-if="props.row.score">[{{ props.row.score }}]</span>
              </div>
              <div class="column is-1 has-text-centered">
                <Sport v-bind:sport="props.row.sport"/>
              </div>
              <div class="column is-4 leftal">
                <BkLine :market="props.row.market" :target="props.row.target" :pivot="props.row.pivot"/>
              </div>
            </div>
          </b-table-column>
          <b-table-column field="placed_time" width="100" sortable :label="$ml.get('bets_placed')" v-slot="props">
            {{ new Date(props.row.placed_time * 1000).toLocaleString() }}
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import State from "@/components/Status";
import Money from "@/components/Money";
import Vue from "vue";
import Sport from "@/components/Sport";
import Multiselect from "vue-multiselect";
import {mapGetters} from "vuex";
import Bk from "@/components/Bk";
import BkLine from "@/components/BkLine";

export default {
  name: "Settled",
  data() {
    return {
      perPage: 15,
      currentPage: 1,
      loading: false,
      items: [],
      sortField: 'placed_time',
      sortOrder: 'desc',
      counters: {
        count: 0,
        turnover: 0,
        profit: 0,
        roi: 0,
      },
      form: {
        modes: [],
        sports: [],
        statuses: [],
        bks: [],
        markets: [],
        targets: [],
        strategies: [],
        rooms: [],
        coef_from: '',
        coef_to: '',
        date_from: null,
        date_to: null,
      },
    }
  },
  components: {
    Sport, State, Money, Multiselect, Bk, BkLine
  },
  computed: {
    ...mapGetters('data', ['sports', 'bet_types', 'bet_modes', 'bookmakers', 'markets', 'targets']),
    strategies() {
      let result = []
      this.$store.getters['data/strategies'].forEach((item) => {
        result.push(item.name)
      })
      return result
    },
    rooms() {
      let result = []
      this.$store.getters['data/myRooms'].forEach((item) => {
        result.push(item.name)
      })
      return result
    }
  },
  methods: {
    submit() {
      this.retrieve()
    },

    retrieve() {
      this.loading = true
      let params = [
        `sort=${this.sortField}`,
        `order=${this.sortOrder}`,
        `page=${this.currentPage}`,
        `per-page=${this.perPage}`,
      ].join('&')

      let data = Object.assign({}, this.form)
      let df = data.date_from
      let dt = data.date_to
      if (df) {
        data.date_from = df.getFullYear() + '-' + (1 + df.getMonth()).toString().padStart(2, '0') + '-' + df.getDate().toString().padStart(2, '0')
      }
      if (dt) {
        data.date_to = dt.getFullYear() + '-' + (1 + dt.getMonth()).toString().padStart(2, '0') + '-' + dt.getDate().toString().padStart(2, '0')
      }

      Vue.prototype.$http.post(process.env.VUE_APP_API_HOST + `bets/settled?${params}`, {search: data}).then(resp => {
        this.items = resp.data.result.items
        this.counters = resp.data.result.counters
      }).catch(err => {
        this.flashMessage.error({
          title: this.$ml.get('common_request_failed'),
          message: this.$ml.get('common_request_failed_message'),
        })
      }).finally(() => {
        this.loading = false
      })
    },

    switchPage(page) {
      this.currentPage = page
      this.retrieve()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.retrieve()
    },
  },
  mounted() {
    this.$store.dispatch('data/strategies')
    this.$store.dispatch('data/myRooms')
    this.retrieve()
  },
  metaInfo() {
    return {
      title: this.$ml.get('titles_bets_settled')
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
</style>